import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct";
import * as dayjs from "dayjs";
import {Dayjs} from "dayjs";
import {format, formatDate, SCHEDULE_FORMATDATE} from "../../utils/contants";
import * as timezone from "dayjs/plugin/timezone";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import * as utc from "dayjs/plugin/utc";

dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export interface Schedule {
  _id: string
  id: string
  secondaryId: string
  startTime: string
  endTime: string
  startHour: string
  startMin: string
  duration: number
  versionId: string
  channelId: string
  assetIds?: string[]
  assetIDs?: string[]
  regionIds?: string[]
  blackoutRegions?: string[]
  blackoutRegionsCodes?: string[]
  blackout?: boolean
  orbisId: string
  isClone?: boolean
  contentLock: boolean
  edited?: boolean
  startDate: string
  endDate: string
  startHourDst?: string
  startMinDst?: string
  startDateDst?: string
  startDateTimeDst?: string
  durationDst?: number
  endTimeDst?: string
  endDateDst?: string
  highlightClass?: string
  checked?: boolean
  qualifiers: any[]
  qualifiersCodes?: string[]
  selectedQualifiers?: {
    name: string
    label: string
  }[]
  releaseYear: string
  programDetails: Program
  exactDuration: number
  externalRefs: any
  programId: string
  genreNames?: string[]
  ratingNames?: string[]
  labels?: any,
  validationErrors?: string[]
  imageBanner: string
}

export class ProgramDetails {
  seasonNumber: number;
  episodeNumber: number;
  programType: string;
  programId: string;
  releaseYear: string;
  origAirDate: string;
  parentTitles: string;
  programImages: string[];
  genreNames: string[];
  ratings: Rating[];
}

export class ScheduleEvent {
  id: string;
  duration: number;
  startDate: string;
  endDate: string;
  programType: string;
  ratings: any[];
  qualifiers: string[];
  programDetails: ProgramDetails;
  programId: string;

  // Blackout information
  labels: any[];
  blackout: boolean;
  blackoutRegions: string[];

  // Used for UI only
  modified: boolean;

  // Return the unique id that will be used on UI based on a given index.
  displayId(index: number): string {
    return 'event-' + index;
  }

  // Check if this event should be displayed or not in a given schedule date at a specific timezone.
  shouldDisplay(selectedTimeZone: Offset, selectedDate: NgbDateStruct): boolean {
    const localStartDate = this.utcStartDate().tz(selectedTimeZone.value);
    const scheduleDate = dayjs.tz(formatDate(selectedDate), SCHEDULE_FORMATDATE, selectedTimeZone.value);
    const nextScheduleDate = scheduleDate.add(1, 'day');
    const isValidTime = (scheduleDate.isSame(localStartDate) || scheduleDate.isBefore(localStartDate))
      && nextScheduleDate.tz(selectedTimeZone.value).isAfter(localStartDate);
    return this.modified || isValidTime;
  }

  // Return the code of 'valid-qualifiers' of this event.
  displayQualifiers(): string[] {
    return this.qualifiers?.map(qualify => qualify.split('epgQualifier')[1]) || [];
  }

  // Return the name of 'valid-ratings' of this event.
  displayRatings(availableRatings: any[] = []): string[] {
    return availableRatings?.filter(r => this.ratings?.map(({id}) => id).includes(r.id)).map(r => r.rating);
  }

  // Return the name of 'valid-regions' of this event.
  displayRegions(regions: any[] = []): string[] {
    return regions
      ?.filter(r => this.blackoutRegions?.includes(r.regionId))
      .map(({regionName}) => regionName?.substring(0, 3).toUpperCase() || '');
  }

  // Format start date using a given timezone and pattern.
  formattedStartDate(timezone = 'GMT', pattern = 'ddd, MMM DD - HH:mm:ss'): string {
    return this.startDate ? this.parseDateTime(this.startDate).tz(timezone).format(pattern) : '';
  }

  // Format end date using a given timezone and pattern.
  formattedEndDate(timezone = 'GMT', pattern = 'ddd, MMM DD - HH:mm:ss'): string {
    return this.endDate ? this.parseDateTime(this.endDate).tz(timezone).format(pattern) : '';
  }

  // Return start date in UTC timezone.
  utcStartDate(): Dayjs {
    return this.parseDateTime(this.startDate);
  }

  // Return end date in UTC timezone.
  utcEndDate(): Dayjs {
    return this.parseDateTime(this.endDate);
  }

  // Return the duration as minute.
  durationInMinute(): number {
    return this.duration / 60;
  }

  // Set the hour of this event using a given number of hour.
  setHour(hour: number, timezone: string) {
    let updated = timezone === 'GMT'
      ? this.utcStartDate().hour(hour)
      : this.utcStartDate().tz(timezone).hour(hour).tz("GMT");
    this.startDate = format(updated);
    // Highlight modified event
    this.modified = true;
  }

  // Set the minute of this event using a given number of minute.
  setMinute(min: number, timezone: string) {
    let updated = timezone === 'GMT'
      ? this.utcStartDate().minute(min)
      : this.utcStartDate().tz(timezone).minute(min).tz("GMT");
    this.startDate = format(updated);
    // Highlight modified event
    this.modified = true;
  }

  // Set the duration of this event using a given number of minute.
  correctDurationInMinute(durationInMinute: number) {
    this.duration = durationInMinute * 60;
    // Highlight modified event
    this.modified = true;
  }

  // Set the start date of this event using a given string.
  correctDuration(duration: number): void {
    this.duration = duration;
    // Highlight modified event
    this.modified = true;
  }

  // Self correct the end date based on current start date and duration.
  correctEndDate(): void {
    // update current event
    this.endDate = format(this.utcStartDate().add(this.duration, 'second'));
    // Highlight modified event
    this.modified = true;
  }

  // Set the start date of this event using a given string.
  correctStartDate(startDate: string): void {
    this.startDate = startDate;
    // Highlight modified event
    this.modified = true;
  }

  // Check if this event has valid program or not.
  hasProgram(): boolean {
    return !!this.programDetails?.programId;
  }

  // Check if this event is gap or not.
  isGap(nextEvent?: ScheduleEvent): boolean {
    if (nextEvent) {
      // Gap with next event
      return this.utcStartDate().add(this.duration, "second").isBefore(nextEvent.utcStartDate());
    }

    // Gap itself
    return this.utcStartDate().add(this.duration, "second").isBefore(this.utcEndDate());
  }

  // Check if this event is overlapped or not.
  isOverlapped(nextEvent?: ScheduleEvent): boolean {
    if (nextEvent) {
      // Overlapped with next event
      return this.utcStartDate().add(this.duration, "second").isAfter(nextEvent.utcStartDate());
    }

    // Overlapped with itself
    return this.utcStartDate().add(this.duration, "second").isAfter(this.utcEndDate());
  }

  // Check if this event has a negative duration.
  isPositiveDuration(): boolean {
    return this.duration > 0 ? true : false;
  }

  // Return the validation of this event based on it's next event info.
  validationStatus(nextEvent: ScheduleEvent): string {
    if (!this.isPositiveDuration()) {
      return 'overlapped-event';
    } else if (this.isGap() || this.isGap(nextEvent)) {
      return 'gap-event';
    } else if (this.isOverlapped() || this.isOverlapped(nextEvent)) {
      return 'overlapped-event';
    }
  }

  // Return the validation of this event based on it's next event info.
  isValid(nextEvent: ScheduleEvent): boolean {
    return !this.isGap(nextEvent) && !this.isOverlapped(nextEvent) && this.isPositiveDuration() && this.hasProgram();
  }

  // Parse a string into dayjs for date manipulation.
  private parseDateTime(date: string): Dayjs {
    return dayjs.utc(date);
  }

  hour(timezone?: string) {
    return this.formattedStartDate(timezone ?? 'GMT', 'HH');
  }

  min(timezone?: string) {
    return this.formattedStartDate(timezone ?? 'GMT', 'mm');
  }
}

export class ScheduleDetails {

  _id: any;
  id: string;
  channelId: string;
  provider: string;
  published: boolean;
  scheduleId: string;
  sourceId: string;
  updatedDate: string;
  createdDate: string;
  processingType: string;
  completed: boolean;
  scheduleDate: string;
  version: number;
  events: ScheduleEvent[];
  externalRefs: any[];
  providerInfo: any[];
  targetPlatform: string;
  contentLock: boolean;
  imageBaseUrl: string;
  scheduledPublishedVersion: number;
  status: string;

  // Indicates if this schedule has any event or not.
  hasEvent(timezone: Offset, date: NgbDateStruct): boolean {
    return !!(this.events
      && this.events?.find(event => event.id !== '0' && event.shouldDisplay(timezone, date)));
  }

  // Return the events of this schedule that will be displayed in a given date and timezone.
  displayedEvent(timezone: Offset, date: NgbDateStruct): ScheduleEvent[] {
    return this.events?.filter(e => e.shouldDisplay(timezone, date));
  }

  isValid(): boolean {
    return this.events.every((event, index) => event.isValid(this.events[index + 1]));
  }
}

export interface ScheduleVersionRequest {
  timezone?: Offset
  channel?: SourceChannel
  date?: NgbDateStruct
}

export interface ScheduleVersion {
  scheduleId: string
  scheduledPublishedVersion?: number
  versions?: ScheduleVersionDetail[]
  contentLock: boolean
}

export interface ScheduleVersionDetail {
  createdDate?: number
  updatedDate?: number
  version?: number
  published?: boolean
  provider?: string
  id?: string
  status: string
}

export interface Offset {
  key?: string
  tooltip?: string
  value?: string
}

export enum EditableEventField {
  DURATION = 'duration',
  HOUR = 'hour',
  MIN = 'min'
}
